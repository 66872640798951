<template>
	<div class="flex justify-center">
		<q-card
			:class="$q.screen.gt.xs ? 'q-pa-md q-mt-xl' : 'q-mt-xl'"
			:style="$q.screen.gt.xs ? 'width: 700px; max-width: 80vw; height: fit-content' : 'width: 100%; height: fit-content'"
		>
				<div class="q-pa-md q-mb-none">Please enter the email address associated with your account, and we will
					get in touch with information to help you get logged in.</div>
			<q-card-section class="text-center">
				<q-input
					outlined
					class="q-mb-sm"
					label="Email"
					bottom-slots
					v-model="email"
					type="email"
					:error="email_error"
					:error-message="email_error_message"
				/>
				<div class="flex justify-center">
					<Recaptcha
						@recaptcha-verified='isRecaptchaVerified'
						@recaptcha-verification-code='getVerificationCode'
					/>
				</div>
				<q-card-actions class="q-ma-none q-pa-none">
					<q-btn
						push
						color="primary"
						background-color='primary'
						class="login-btn full-width q-mt-md"
						label="Reset Password"
						@click="sendResetPasswordEmail"
						:disabled="!recaptcha_verified || !email"
					/>
				</q-card-actions>
			</q-card-section>
		</q-card>
	</div>
</template>
<script>
import Recaptcha from '@/components/login/LoginRecaptcha.vue';
import LoginAPI from '@/services/api/Login.js';
export default ({
	name: 'ResetPassword',
	meta: {
		title: 'Alert Cascade - Reset Password'
	},
	components: {
		Recaptcha,
		LoginAPI,
	},
	data(){
		return{
			email: null,
			recaptcha_verified: false,
			verification_code: '',
			email_error: false,
			email_error_message: ''
		}
	},
	methods:{
		isRecaptchaVerified(val){
			this.recaptcha_verified = val
		},
		getVerificationCode(code){
			this.verification_code = code
		},
		async sendResetPasswordEmail(){
			this.email_error = false
			this.email_error_message = ''
			let response = await LoginAPI.resetPassword({
				"email":this.email,
				'captcha_verified': this.recaptcha_verified,
				"recaptcha": this.verification_code,
			})
			if (response.error) {
				if (Object.keys(response.data)[0] == 'email'){
					this.email_error = true
					this.email_error_message = response.data.email[0]
				}
				else{
					this.$q.notify({
						timeout: 6700,
						message: Object.values(response.data),
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
				}
				return
			}
			this.$q.loading.show({
				message: response.data.success
			})
			this.timer = setTimeout(() => {
				this.$q.loading.hide()
				this.$q.notify('Password reset email successfully sent.')
				this.$router.push({ name: "Login User" })
			}, 3000)
		}
	}
})
</script>
